
        import V from 'voUtils/V.js';
        import Vue from 'vue';
        import {voMsg,voMsgPl} from './voUtils/voI18n.js';
        import DateEx from './voUtils/DateEx.js';

        Vue.prototype.VOFFICE = window.VOFFICE;

        var voFormatDate = DateEx.formatDate;

        import AirbnbStyleDatepicker from 'base/vue-airbnb-style-datepicker'
        import 'base/vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'

        var vueApps = {};

        var methods = {voMsg,voMsgPl,voFormatDate};

        
            import App0 from './app/dummy.js';
            vueApps['app/dummy.js'] = App0;
        

            import App1 from './redirects.js';
            vueApps['redirects.js'] = App1;
        

            import App2 from './app/search.js';
            vueApps['app/search.js'] = App2;
        

            import App3 from './app/UnitsOfTheDay.js';
            vueApps['app/UnitsOfTheDay.js'] = App3;
        

            import App4 from './app/unit.js';
            vueApps['app/unit.js'] = App4;
        

            import App5 from './app/SimilarUnits.js';
            vueApps['app/SimilarUnits.js'] = App5;
        

            import App6 from './app/navbar.js';
            vueApps['app/navbar.js'] = App6;
        

            import App7 from './app/LastViewedUnits.js';
            vueApps['app/LastViewedUnits.js'] = App7;
        

            import App8 from './app/Ubersicht-A-Z.js';
            vueApps['app/Ubersicht-A-Z.js'] = App8;
        

            import App9 from './app/aktuelleUnits.js';
            vueApps['app/aktuelleUnits.js'] = App9;
        

            import App10 from './app/ferienammeer.js';
            vueApps['app/ferienammeer.js'] = App10;
        

            import App11 from './app/ferienmitpool.js';
            vueApps['app/ferienmitpool.js'] = App11;
        

            import App12 from './app/grossligurien.js';
            vueApps['app/grossligurien.js'] = App12;
        

            import App13 from './app/ferienohneauto.js';
            vueApps['app/ferienohneauto.js'] = App13;
        

            import App14 from './app/ferienhundeerlaubt.js';
            vueApps['app/ferienhundeerlaubt.js'] = App14;
        

            import App15 from './app/ferienligurien.js';
            vueApps['app/ferienligurien.js'] = App15;
        

        
                import Comp16 from './comp/DateRange.vue';
                Vue.component('voffice-daterange',Comp16);
            

                import Comp17 from './comp/ImgFallback.vue';
                Vue.component('img-fallback',Comp17);
            

                import Comp18 from './comp/PhotoSwipe.js';
                Vue.component('voffice-photoswipe',Comp18);
            

                import Comp19 from './comp/FavElement.vue';
                Vue.component('voffice-fav-element',Comp19);
            

                import Comp20 from './comp/MaxTextSize.js';
                Vue.component('voffice-max-text-size',Comp20);
            

                import Comp21 from './comp/pricelist.vue';
                Vue.component('price-tab',Comp21);
            

                import Comp22 from './comp/pricetotal.vue';
                Vue.component('price-total',Comp22);
            

                import Comp23 from './comp/currencymod.vue';
                Vue.component('currency-mod',Comp23);
            

                import Comp24 from './comp/PopupDialog.vue';
                Vue.component('voffice-popup-dlg',Comp24);
            

                import Comp25 from './comp/NewsletterSubsription.js';
                Vue.component('voffice-newsletter-subscription',Comp25);
            

                import Comp26 from './comp/DateRangeExt.vue';
                Vue.component('voffice-daterange-ext',Comp26);
            

                import Comp27 from './comp/GuestBox.vue';
                Vue.component('voffice-guestbox',Comp27);
            

                import Comp28 from './comp/Accordion.vue';
                Vue.component('voffice-accordion',Comp28);
            

                import Comp29 from './comp/PetsAllowedIconComp.vue';
                Vue.component('pets-allowed',Comp29);
            

                import Comp30 from './comp/Booking.js';
                Vue.component('voffice-booking',Comp30);
            

                import Comp31 from './comp/DocBox.vue';
                Vue.component('voffice-doc-box',Comp31);
            

                import Comp32 from './comp/ErrorMessage.vue';
                Vue.component('voffice-error-message',Comp32);
            

                import Comp33 from './comp/SearchFilterTokens-v2.vue';
                Vue.component('search-filter-tokens-v2',Comp33);
            

                import Comp34 from './comp/PopupBox.vue';
                Vue.component('voffice-popup-box',Comp34);
            

                import Comp35 from './comp/SearchFilterTokens.vue';
                Vue.component('search-filter-tokens',Comp35);
            

                import Comp36 from './comp/openstreetmap.vue';
                Vue.component('voffice-openstreetmap',Comp36);
            

                import Comp37 from './comp/gmap.js';
                Vue.component('voffice-gmap',Comp37);
            

                import Comp38 from './imageCarousel.vue';
                Vue.component('img-carousel',Comp38);
            

                import Comp39 from './offershelp.vue';
                Vue.component('offer-help',Comp39);
            

                import Comp40 from './comp/IncreaseNumber.vue';
                Vue.component('increase-number',Comp40);
            

                import Comp41 from './comp/ContactForm.js';
                Vue.component('voffice-form',Comp41);
            

                import Comp42 from './comp/Voucher.vue';
                Vue.component('voffice-voucher',Comp42);
            

                import Comp43 from './pricehelp.vue';
                Vue.component('price-help',Comp43);
            

        Vue.mixin({
            methods: methods
        });

        const datepickerOptions = {
            days: voMsg('weekdayslist').split(','),
            daysShort: voMsg('weekdayslist.short').split(','),
            monthNames: voMsg('monthlist.full').split(',')
        };
        Vue.use(AirbnbStyleDatepicker, datepickerOptions);


    	var passThrough = function (val) {
			return val || '';
		};

    	var filterCodes = [
    	    'vlang',
    	    'vautolang',
    	    'vsoption',
    	    'vsPropOption'
    	  ];

        for (let fc of filterCodes) {
    		Vue.filter(fc, passThrough);
    	};


        var vueAppEls = document.body.querySelectorAll('[vo-vue-app]');

        for (let el of vueAppEls) {
            let vName = el.getAttribute("vo-vue-app");
            let v = vueApps[vName];
            if (v) {
                v.el = el;
                V.log("creating vue app",vName);

                let app = new Vue(v);
            }

        }

        VOFFICE.afterAllLoaded = function(){
            document.documentElement.classList.add("app-loaded");
        };

        window.addEventListener('beforeunload', function (event) {
        	document.documentElement.classList.add("app-leaving");
        });


    